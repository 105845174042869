import React from 'react';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import Page from '../components/layout/Page';
import withRoot from '../utils/withRoot';

const NotFoundPage = function NotFoundPage() {
  return (
    <div>
      <Helmet>
        <title>Not Found</title>
      </Helmet>

      <Page title="Not Found">
        <Typography paragraph color="primary" gutterBottom variant="h3" component="span">
          Hey! You just hit a page that doesn&apos;t exist.
        </Typography>
      </Page>
    </div>
  );
};

export default withRoot(NotFoundPage);
